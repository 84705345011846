import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {LoadingBar, PageLoader, SpinLoader} from "./Loader";
import history from "../../libs/history";

import Aqp from '../../libs/mk-react-aqp';

import Pagination from "./Pagination";
import createLink from "../../libs/createLink";
import DateSince from "./Date";
import {fetchReportingComments} from "../../creators/reporting.comments";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormFields from "./FormFields";
import {createReportingComment} from "../../creators/reporting.comment";


let mapping = {
    "sort" : "trie"
};


class ReportingComments extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {
            limit: 11,
            page: 1,
            sort: "createdAt"
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: `/reporting/${this.props.parentId}`,
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            content : ""
        };

        this.handleChangePage = this.handleChangePage.bind(this);

        this.fetchReportingComments(this.state.filter);

    }


    fetchReportingComments(filter){
        this.props.fetchReportingComments(this.props.parentId,this.aqp.set(filter));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reportingCommentsTotal !== this.props.reportingCommentsTotal && this.props.reportingCommentsInitialized) {
            let totalPage = this.props.reportingCommentsTotal ? Math.ceil(this.props.reportingCommentsTotal / this.state.filter.limit) : 0;
            if(totalPage > this.state.filter.page && this.props.reportingCommentsTotal > prevProps.reportingCommentsTotal){
                this.handleChangePage(totalPage, this.aqp.queryUrl(this.state.filter, mapping).link);
            }
        }
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            history.push(link);
        } else {
            history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchReportingComments(filter);
        window.scrollTo(0, 0);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.reportingCommentLoading && this.state.content.length >= 1) {
            this.props.createReportingComment(this.props.parentId, {content : this.state.content});
            this.setState({content : ""});
        }
    }


    render() {
        let totalPage = this.props.reportingCommentsTotal ? Math.ceil(this.props.reportingCommentsTotal / this.state.filter.limit) : 0;
        return !this.props.reportingCommentsInitialized ? (<PageLoader />) : (
            <Fragment>
                {this.props.reportingCommentsLoading && (<LoadingBar />)}
                {this.props.reportingComments.map((item, index) =>(
                    <Row className={`mt-4 ${(index-1) % 2 ? "justify-content-end" : ""}`} key={item._id}>
                        <Col sm={1} >
                            {item.owner && item.owner.profile && item.owner.profile.avatar && (<img src={`${item.owner.profile.avatar}?width=80&height=80`} alt={"avatar"} />)}
                        </Col>
                        <Col sm={8}>
                            <Card >
                                <Card.Header className={"flex"}>
                                    <Col >
                                        <a href={createLink("userProfile", item.owner)} target={"new"}>{item.owner && item.owner.username}</a>
                                    </Col>
                                    <Col sm={3} className={"text-right"} >
                                        <DateSince date={item.createdAt} />
                                    </Col>
                                </Card.Header>
                                <Card.Body>
                                    {item.content}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ))}

                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
                {this.props.reportingCommentLoading && (<LoadingBar />)}
                <Form onSubmit={(e) => this.handleSubmit(e)} className={"mt-5"}>
                        <FormFields
                            group={{controlId : "content"}}
                            type={"input"}
                            controle={{
                                as : "textarea",
                                type : "textarea",
                                name : "content",
                                rows : "5",
                                placeholder : "Ajouter un nouveau commentaire",
                                value : this.state.content,
                                onChange : (event) =>{this.setState({content : event.target.value})}
                            }}
                        />

                    <Button variant="primary" type="submit" className={"btn-loading fit-content mb-5"}>
                        Ajouter un commentaire {this.props.reportingCommentLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                    </Button>
                </Form>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        reportingCommentsInitialized: state.reportingComments.initialized,
        reportingCommentsLoading: state.reportingComments.loading,
        reportingComments: state.reportingComments.data,
        reportingCommentsTotal: state.reportingComments.total,

        reportingCommentInitialized: state.reportingComment.initialized,
        reportingCommentLoading: state.reportingComment.loading,
        reportingComment: state.reportingComment.data,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReportingComments,
    createReportingComment
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportingComments);
