import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./contentRatingList.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONTENT_RATINGS } from '../../const/content';
import { updateMediaContentRating } from '../../creators/media';

class ContentRatingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentRating : this.props.contentRating || 0
        };
        this.handleChangeContentRating = this.handleChangeContentRating.bind(this);
    }

    handleChangeContentRating(contentRating){
        this.setState({contentRating});
        this.props.updateMediaContentRating(this.props.mediaId, {contentRating, contentRatingModerated : this.props.contentRatingModerated || false, isContentRatingReview : this.props.isContentRatingReview || false}).then(() =>{

        }).catch(() =>{
            this.setState({contentRating : this.props.contentRating});
            window.alert("impossible de mettre a jour le contentRating");
        })
    }

    render() {
        return (
            <div className="icon-list">
                {CONTENT_RATINGS.map((contentRating, idx) => (
                    <button
                        className={`icon-button ${contentRating.className} ${this.state.contentRating === contentRating.value ? "active" : ""}`}
                        onClick={(e) =>{
                            e.stopPropagation();
                            this.handleChangeContentRating(contentRating.value)
                        }}
                        key={idx}
                    ><FontAwesomeIcon icon={contentRating.icon}/></button>
                ))}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateMediaContentRating
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentRatingList);
