import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {PageLoader, SpinLoader} from "../components/Loader";
import {fetchReporting, resetReporting, updateReporting} from "../../creators/reporting";
import FormFields from "../components/FormFields";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import createLink from "../../libs/createLink";
import DateSince from "../components/Date";
import ReportingComments from "../components/ReportingComments";
import DraftContentReader from "../components/editor/DraftContentReader";
import _ from "lodash"
import {legendToTitle} from "../../libs/helper";


class Reporting extends React.Component {
    constructor(props) {
        super(props);
        this.currentId = this.props.match.params.id || false;
        this.state = {
            status : 0
        };
        this.linkContent = '';
        this.props.resetReporting();
        if(this.currentId){
            this.props.fetchReporting(this.currentId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reportingInitialized !== this.props.reportingInitialized) {
            this.setState({status: this.props.reporting.status || 0});
            let content = this.props.reporting.content;
            let owner = _.get(content,"owner") || content;
            switch (this.props.reporting.kind) {
                case"media":
                    if(owner){
                        const title = legendToTitle(content.legend || "");
                        if(content === "video"){
                            this.linkContent = <a href={createLink("mediaviewerPhoto", {...owner, media : content})} target={"_new"}>{title}</a>
                        }
                        else{
                            this.linkContent = <a href={createLink("mediaviewerVideo", {...owner, media : content})} target={"_new"}>{title}</a>
                        }
                    }
                    else{
                        this.linkContent = "";
                    }

                break;
                case"article":
                    if(owner){
                        this.linkContent = <a href={createLink("article", {...owner, article : content})} target={"_new"}>{content.title}</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                break;
                case"album":
                    if(owner){
                        this.linkContent = <a href={createLink("userAlbum", {...owner, album : content})} target={"_new"}>{content.title}</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                case"status":
                    if(owner){
                        this.linkContent = <a href={createLink("userProfile", {...owner})} target={"_new"}>Voir le profil</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                case"user":
                    if(owner){
                        this.linkContent = <a href={createLink("userProfile", {...owner})} target={"_new"}>Voir le profil</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                case"topic":
                    let topic = this.props.reporting.topic;
                    let topicOwner = _.get(topic,"owner") || {};
                    if(topic && topicOwner && topic.forum){
                        this.linkContent = <a href={createLink("forumTopic", {...topicOwner, topic : topic, category : topic.forum})} target={"_new"}>{topic.title}</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                case"comment":
                    let parentKind = _.get(content, "parentKind");
                    if(parentKind === "media"){
                        let media = this.props.reporting.media;
                        let mediaOwner = _.get(media,"owner") || {};
                        const title = legendToTitle(media.legend || "");
                        if(this.props.reporting.media.kind === "video"){
                            this.linkContent = <a href={`${createLink("mediaviewerVideo", {...mediaOwner, media : media})}#commentId=${content._id}`} target={"_new"}>{title}</a>
                        }
                        else{
                            this.linkContent = <a href={`${createLink("mediaviewerPhoto", {...mediaOwner, media : media})}#commentId=${content._id}`} target={"_new"}>{title}</a>
                        }
                        
                    }
                    else if(parentKind === "article"){
                        let article = this.props.reporting.article;
                        let ArticleOwner = _.get(article,"owner") || {};
                        this.linkContent = <a href={`${createLink("article", {...ArticleOwner, article : article})}#commentId=${content._id}`} target={"_new"}>{article.title}</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                case"topicComment":
                    let topicComment = this.props.reporting.topic;
                    let TopicOwner = _.get(topicComment,"owner") || {};
                    if(topicComment && TopicOwner && topicComment.forum){
                        if(this.props.reporting.currentPage){
                            topicComment = {
                                ...topicComment,
                                page : this.props.reporting.currentPage,
                                ancre : `#${content._id}`
                            }
                        }
                        this.linkContent = <a href={createLink("forumTopic", {...TopicOwner, topic : topicComment, category : topicComment.forum})} target={"_new"}>{topicComment.title}</a>
                    }
                    else{
                        this.linkContent = "";
                    }
                    break;
                default:
                    this.linkContent = "";
            }

        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.reportingLoading) {
            this.props.updateReporting(this.props.reporting._id, this.state);
        }
    }


    render() {
        return !this.props.reportingInitialized ? (<PageLoader />) :(
            <Fragment>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Form.Row>
                        <Col sm={2} className={"pt-2"}>
                            Changer le statut
                        </Col>
                        <Col>
                            <FormFields
                                group={{controlId : "status"}}
                                type={"select"}
                                controle={{
                                    name : "status",
                                    value : this.state.status,
                                    options : [{value : 0, label : "Nouveau"}, {value : 1, label : "En cours"}, {value : 2, label : "Résolu"}],
                                    onChange : (event) =>{this.setState({status : event.target.value})}
                                }}
                            />
                        </Col>
                        <Button variant="primary" type="submit" className={"btn-loading fit-content"}>
                            Valider {this.props.reportingLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        </Button>
                    </Form.Row>
                </Form>
                <Col sm={12} className={"p-0"}>
                    Accés au contenu reporté : {this.linkContent}
                </Col>
                <Row className={"mt-4"}>
                    <Col sm={1} >
                        {this.props.reporting.owner && this.props.reporting.owner.profile && this.props.reporting.owner.profile.avatar && (<img src={`${this.props.reporting.owner.profile.avatar}?width=80&height=80`} alt={"avatar"} />)}
                    </Col>
                    <Col>
                        <Card >
                            <Card.Header className={"flex"}>
                                <Col >
                                    <a href={createLink("userProfile", this.props.reporting.owner)} target={"new"}>{this.props.reporting.owner && this.props.reporting.owner.username}</a>
                                </Col>
                                <Col sm={3} className={"text-right"} >
                                    <DateSince date={this.props.reporting.createdAt} />
                                </Col>
                            </Card.Header>
                            <Card.Body>
                                {this.props.reporting.message}
                                {this.props.reporting.kind === "topicComment" && _.get(this.props.reporting, "content.contentRaw") && (
                                    <footer className="blockquote-footer pt-3">
                                        <a href={createLink("userProfile", this.props.reporting.content.owner)} target={"new"}>{this.props.reporting.content.owner && this.props.reporting.content.owner.username}</a> - <DraftContentReader contentRaw={this.props.reporting.content.contentRaw} />
                                    </footer>
                                )}
                                {(this.props.reporting.kind === "comment" || this.props.reporting.kind === "status") && (
                                    <footer className="blockquote-footer pt-3">
                                         <a href={createLink("userProfile", this.props.reporting.content.owner)} target={"new"}>{this.props.reporting.content.owner && this.props.reporting.content.owner.username}</a> - {this.props.reporting.content.text}
                                    </footer>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ReportingComments match={this.props.match} parentId={this.props.reporting._id} />
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        reportingInitialized: state.reporting.initialized,
        reporting: state.reporting.data,
        reportingLoading: state.reporting.loading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReporting,
    resetReporting,
    updateReporting
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
