import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENT_RATINGS_REVIEWS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchModerationContentRatingsReviews = (query = {}) => fetchAction(`/moderation-contents/medias-content-rating-review?${queryString.stringify(query)}`);

export const resetModerationContentRatingsReviews = () => redux.actions.reset();


export const moderationContentRatingsReviews = redux.reducer();