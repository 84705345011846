import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Table} from "react-bootstrap";
import {LoadingBar, PageLoader} from "./Loader";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Aqp from '../../libs/mk-react-aqp';

import Pagination from "./Pagination";
import ColoneTn from "./ColoneTh";
import FormFields from "./FormFields";
import {fetchReportings} from "../../creators/reportings";
import createLink from "../../libs/createLink";
import DateSince from "./Date";


let mapping = {
    "sort" : "trie"
};


class ReportingsList extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 20,
            page: 1,
            sort: "-createdAt",
            status : "0"
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/reportings",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1}
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.fetchReportings(this.state.filter);

    }


    fetchReportings(filter){
        this.props.fetchReportings(this.aqp.set(filter));
    }

    handleStatus(value){
        let filter = {...this.state.filter, page : 1, status : value};
        this.props.history.push("/reportings");
        this.setState({filter});
        this.fetchReportings(filter);
        window.scrollTo(0, 0);
    }

    handleSort(value){
        let filter = {...this.state.filter, page : 1, sort : value};
        this.props.history.push("/reportings");
        this.setState({filter});
        this.fetchReportings(filter);
        window.scrollTo(0, 0);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchReportings(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.reportingsTotal ? Math.ceil(this.props.reportingsTotal / this.state.filter.limit) : 0;
        return !this.props.reportingsInitialized ? (<PageLoader />) : (
            <Fragment>
                <Col sm={2}>
                    <FormFields
                        group={{controlId : "status"}}
                        type={"select"}
                        controle={{
                            name : "status",
                            value : this.state.filter.status,
                            options : [{value : 0, label : "Nouveaux"}, {value : 1, label : "En cours"}, {value : 2, label : "Résolus"}],
                            onChange : (event) =>{this.handleStatus(event.target.value)}
                        }}
                    />
                </Col>
                {this.props.reportingsLoading && (<LoadingBar />)}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"Pseudo"} currentSort={this.state.filter.sort} sort={"username"} handleSort={this.handleSort}  />
                        <ColoneTn label={"Type de contenu"} currentSort={this.state.filter.sort} sort={"kind"} handleSort={this.handleSort}  />
                        <ColoneTn label={"Dernier commentaire"}  />
                        <ColoneTn label={"Mise à jour"} currentSort={this.state.filter.sort} sort={"updateAt"} handleSort={this.handleSort}  />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.reportings.map(item =>(
                        <tr key={item._id}>
                            <td>{item.owner && item.owner._id ? (<a href={createLink("userProfile", item.owner)} target={"new"}>{item.owner.username}</a>) : "utilisateur introuvable"}</td>
                            <td>{item.typeName}</td>
                            <td>{item.lastCommentOwner && item.lastCommentOwner.username}</td>
                            <td><DateSince date={item.updatedAt} currentFormat={false}/></td>
                            <td>
                                <Link to={`/reporting/${item._id}`} className="btn btn-outline-primary btn-sm">
                                    <FontAwesomeIcon
                                        icon="edit"
                                    />
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        reportingsInitialized: state.reportings.initialized,
        reportingsLoading: state.reportings.loading,
        reportings: state.reportings.data,
        reportingsTotal: state.reportings.total
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReportings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportingsList);
