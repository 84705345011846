import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { fetchModerationContentRatings, resetModerationContentRatings, updateModerationContentRatings } from '../../../../creators/moderation.contentRatings';
import "./ContentRating.scss"
import { LoadingBar } from '../../../components/Loader';
import { CONTENT_RATINGS } from '../../../../const/content';
import { config } from '../../../../config';
import { removeKeyEmpty } from '../../../../libs/helper';
import ContentPhotos from '../../../components/ContentPhotos';
import ContentVideos from '../../../components/ContentVideos';
import ToggleSwitch from '../../../components/toggleSwitch/ToggleSwitch';
import AutocompleteUser from '../../../components/AutocompleteUser';
import _ from 'lodash';

const kindArray = [
    {
        value : "gallery",
        label : "Photos"
    },
    {
        value : "video",
        label : "Vidéos"
    }
]

const contentisArchiveArray = [
    {
        value : true,
        label : "Archives"
    },
    {
        value : "",
        label : "Contenu récent"
    }
]

const filter = {
    gender : "",
    contentRating : "",
    kind : "gallery",
    ownerId : "",
    isArchive : ""
}

class ContentRating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMedia : null,
            filter
        };
        this.fetchModerationContentRatings(filter);
        this._submit = this._submit.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleFilterKind = this.handleFilterKind.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContentRatings();
    }

    fetchModerationContentRatings(query = {}){
        this.props.resetModerationContentRatings();
        query = removeKeyEmpty(query);
        this.setState({currentKind : query.kind});
        this.props.fetchModerationContentRatings(query);
    }

    handleFilter(key, value){
        let filter = this.state.filter;
        filter = {...filter, [key] : value};
        this.setState({filter});
        
    }
    handleFilterKind(value){
        let filter = this.state.filter;
        filter = {...filter, kind : value};
        this.setState({filter});
        this.fetchModerationContentRatings(filter)
    }

    _submit(){
        const mediaIds = this.props.moderationContentRatings.map(media => media._id);
        if(mediaIds.length){
            this.props.updateModerationContentRatings({mediaIds}).then(() =>{
                this.fetchModerationContentRatings(this.state.filter);
            })
        }
    }

    render() {
        const moderationContentRatings = Array.isArray(this.props.moderationContentRatings) ? this.props.moderationContentRatings :  [];
        const isAdmin = _.get(this.props, "currentUser.role") === "administrator";
        return (
            <div id="content-rating">
                <div className="content-rating-header">
                    <div className="content-rating-header-filter">
                        <div className="content-rating-header-filter-input-group">
                            <ToggleSwitch
                                options={kindArray}
                                value={this.state.filter.kind}
                                onChange={(value) => this.handleFilterKind(value)}
                            />
                        </div>
                        <div className="content-features-header-filter-separation"></div>
                        <div className="content-rating-header-filter-input-group">
                            <select 
                                id="filter-gender" 
                                className="filter-select" 
                                value={this.state.filter.gender} 
                                onChange={(e) => this.handleFilter("gender", e.target.value)}
                            >
                                <option value="">Tous les genres</option>
                                {config.genderListProfile.map((item, index) => (
                                    <option key={index} value={index + 1}>{item}</option>
                                ))}
                            </select>
                        </div>

                        <div className="content-rating-header-filter-input-group">
                            <select 
                                id="filter-content-rating" 
                                className="filter-select" 
                                value={this.state.filter.contentRating} 
                                onChange={(e) => this.handleFilter("contentRating", e.target.value)}
                            >
                                <option value="">Tous les contents</option>
                                {CONTENT_RATINGS.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="content-rating-header-filter-input-group">
                            <AutocompleteUser onUserSelect={(user) =>this.handleFilter("ownerId", user && user._id)} />
                        </div>
                        <div className="content-rating-header-filter-input-group">
                            <select 
                                id="filter-content-rating-is-archive" 
                                className="filter-select" 
                                value={this.state.filter.isArchive} 
                                onChange={(e) => this.handleFilter("isArchive", e.target.value)}
                            >
                                {contentisArchiveArray.map((item, index) => (
                                    <option key={index} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-right">
                            <button className="filter-submit" onClick={() =>this.fetchModerationContentRatings(this.state.filter)} >Valider</button>
                        </div>
                    </div>
                </div> 
                {this.props.moderationContentRatingsLoading ? (
                    <LoadingBar />
                ) : (
                    <>
                    <p>
                        <strong>Médias à traiter :</strong> {this.props.moderationContentRatingsTotal || 0}
                    </p>
                    {this.state.filter.kind === "video" ? (
                        <ContentVideos 
                            moderationContent={moderationContentRatings} 
                            isAdmin={isAdmin}
                        />
                    ) : (
                        <ContentPhotos 
                            moderationContent={moderationContentRatings} 
                            isAdmin={isAdmin}
                        />
                    )}
                    </>
                    
                )}
               <div className="content-rating-footer">
                    <button className="content-submit" onClick={() =>this._submit()} >Valider</button>
               </div>    
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContentRatings : state.moderationContentRatings.data,
        moderationContentRatingsLoading : state.moderationContentRatings.loading,
        moderationContentRatingsInitialized : state.moderationContentRatings.initialized,
        moderationContentRatingsTotal : state.moderationContentRatings.total,
        currentUser : _.get(state,"currentUser.data"),
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContentRatings,
    resetModerationContentRatings,
    updateModerationContentRatings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentRating);
