import React from 'react';
import {Router, withRouter, Switch, Route} from 'react-router-dom';
import history from '../../libs/history';
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import logo from '../../images/logo.svg';
import NavBar from "./NavBar";
import Home from "../pages/home/Home";
import {fetchCurrentUser} from "../../creators/currentUser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Members from "../pages/Members";
import Moderations from "../pages/moderations/Moderations";
import Member from "../pages/member/Member";
import Reportings from "../pages/Reportings";
import Reporting from "../pages/Reporting";
import Tags from "../pages/Tags";
import Stats from "../pages/stats/Stats";
import {Can} from "../../ability-context";
import Contents from "../pages/contents/Contents";
import {logoutUser} from "../../creators/login";
import MoteurAFacettes from "../pages/facettes/Index";
import KeywordsWarnings from "../pages/keywordsWarnings/KeywordsWarnings";
import AlertMonitoring from "../components/alertMonitoring/AlertMonitoring";
import Configurations from "../pages/configurations/Configurations";
import Classifications from '../pages/classifications/Classifications';


// const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);


class Main extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // if (token) {
    //     //     this.props.fetchCurrentUser();
    //     // }
    // }

    static propTypes() {
        return {
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
        }
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if(nextProps.location !== this.props.location){
    //         return true;
    //     }
    //     if(nextProps.currentUserInitialized !== this.props.currentUserInitialized){
    //         return true;
    //     }
    //     return false;
    // }


    render() {

        return (
            <Can action={["is-admin", "contentRatingAccess", "contentFeatureAccess"]} subject="all">
                <Container>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin:'10px 0'}}>
                        <img src={logo} alt={"logo"} height={25} />
                        <div className={"cursor-pointer text-primary"} onClick={()=>this.props.logoutUser()}>Deconnexion</div>
                    </div>
                    <AlertMonitoring />
                    <NavBar {...this.props} />
                    <Router history={history}>
                        <Switch>
                            <Route path="/classifications" component={Classifications}/>
                            <Can action={"is-admin"} subject="all">
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/members/:page([0-9]+)?"  component={Members}/>
                                <Route path="/member/:id([A-Za-z0-9_-]+)"   component={Member}/>
                                <Route exact path="/reportings/:page([0-9]+)?"  component={Reportings}/>
                                <Route path="/reporting/:id([A-Za-z0-9_-]+)/:page([0-9]+)?"   component={Reporting}/>
                                <Route path="/tags" component={Tags}/>
                                <Route path="/configurations" component={Configurations}/>
                                <Route path="/stats" component={Stats}/>
                                <Route path="/moderations" component={Moderations}/>
                                <Route path="/contents" component={Contents}/>
                                <Route path="/moteur-a-facettes" component={MoteurAFacettes}/>
                                <Route path="/keywords-warnings" component={KeywordsWarnings}/>
                            </Can>
                        </Switch>
                    </Router>
                </Container>
            </Can>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCurrentUser,
    logoutUser
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
