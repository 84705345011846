import React, { Component } from "react";
import "./ContentVideos.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faHistory } from "@fortawesome/pro-solid-svg-icons";
import ContentRatingList from "./contentRatingList";
import { legendToTitle } from "../../libs/helper";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import createLink from "../../libs/createLink";
import moment from "moment-timezone";
import ContentHistory from "./ContentHistory";

const genderMap = {
  0 : {
      label : "Non definis",
      labelShort : "NA",
      className : ""
  },
  1 : {
      label : "Homme",
      labelShort : "H",
      className : "man"
  },
  2 : {
      label : "Femme",
      labelShort : "F",
      className : "women"
  },
  3 : {
      label : "Couple",
      labelShort : "C",
      className : "couple"
  },
  4 : {
      label : "Couple",
      labelShort : "C",
      className : "couple"
  },
  5 : {
      label : "Couple",
      labelShort : "C",
      className : "couple"
  },
  6 : {
      label : "Travesti",
      labelShort : "T",
      className : "trave"
  }
}


const CardContentRating = ({
  media,
  title,
  setCurrentMedia
}) => {
  const previewUrl = Array.isArray(media.previewUrl)
  ? media.previewUrl
  : [media.previewUrl];
 return (
  <div className="content-container">
      <div className="images-container">
        {previewUrl.map((thumb, thumbIndex) => (
          <div key={thumbIndex} className="image-container">
            <img
              src={`${thumb}?width=150&height=150`}
              alt={`${title} - ${thumbIndex}`}
            />
          </div>
        ))}
        <div className="image-container with-button">
          <img
            src={`${previewUrl[0]}?width=150&height=150`}
            alt={`${title} - Play`}
          />
          <button
            className="player-button"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentMedia(media)}
            }
          >
            <FontAwesomeIcon icon={faCirclePlay} />
          </button>
        </div>
      </div>
    </div>
 )
}

const CardContentFeature = ({
  media,
  title
}) => {
  const previewUrl = Array.isArray(media.previewUrl)
  ? media.previewUrl
  : [media.previewUrl];
 return (
  <div className="content-container-video">
    <div className="video-container"
      onClick={(e) => {
        e.stopPropagation();
        }
      }
      >
          <div className="video-player"
          onClick={(e) => {
          e.stopPropagation();
          }
        }
          >
            <ReactPlayer
            url={media.videoUrl}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            config={{
              file: {
                attributes: {
                  style: {
                    maxHeight: '400px', // Limite la hauteur maximale
                    width: 'auto', // Conserve les proportions
                  },
                },
              },
            }}
            className="player"
          />
          </div>
      </div>
      <div className="images-container-scroll">
        {previewUrl.map((thumb, thumbIndex) => (
          <div key={thumbIndex} className="image-container">
            <img
              src={`${thumb}?width=150&height=150`}
              alt={`${title} - ${thumbIndex}`}
            />
          </div>
        ))}
      </div>
    </div>
 )
}

class ContentVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMedia: null, 
      contentHistoryId : null
    };
  }

  setCurrentMedia = (media) => {
    this.setState({ currentMedia: media });
  };


  render() {
    const { 
      moderationContent = [], 
      mediaIdsSelected = [],
      handleMediaIdsSelected,
      contentRatingModerated = false,
      isAdmin = false,
      isContentRatingReview = false
  } = this.props;

  const stateCurrentMediaGender = (this.state.currentMedia && this.state.currentMedia.owner && this.state.currentMedia.owner.profile && this.state.currentMedia.owner.profile.gender) || 0;
  const stateCurrentGender = genderMap[stateCurrentMediaGender];

    return (
      <div className="content-rating-video">
        {moderationContent.map(media => {
          const title = media.legend ? legendToTitle(media.legend) : "";
          
          const mediaId = media._id ? media._id.toString() : null;
          const mediaGender = (media.owner && media.owner.profile && media.owner.profile.gender) || 0;
          const currentGender = genderMap[mediaGender];
          return (
            <div key={mediaId} className={`content-card ${mediaIdsSelected.includes(mediaId) ? "active" : ""}`} onClick={()=>handleMediaIdsSelected ? handleMediaIdsSelected(mediaId) : null}>
              {handleMediaIdsSelected ? <CardContentFeature media={media} title={title} /> : <CardContentRating media={media} title={title} setCurrentMedia={this.setCurrentMedia} />}
              <div className="content-card-footer">
                <div className="content-card-footer-left" >
                  <div 
                    className="content-rating-video-title"
                    onClick={(e) => {
                      e.stopPropagation();
                      }
                    }
                  >
                    <a href={createLink("mediaviewerVideo", {media : media})} rel="noopener noreferrer" target="_blank" title="Voir la vidéo" onClick={(e) => {e.stopPropagation();}}>{title}</a>
                  </div>
                  <div className="content-rating-video-duration" onClick={(e) => {e.stopPropagation()}}>Durée : {media.duration ? moment.utc(media.duration * 1000).format("mm:ss") : "00:00"} mns <span className={`gender-button ${currentGender.className}`}>{currentGender.label}</span></div>
                </div>
                {isAdmin && (<button className="info-button"  onClick={(e) => {this.setState({contentHistoryId : media._id}); e.stopPropagation();}}><FontAwesomeIcon icon={faHistory}/></button>)}
                <div className="footer-icon-list" onClick={(e) => {e.stopPropagation()}}>
                      <ContentRatingList contentRatingModerated={contentRatingModerated} contentRating={media.contentRating} mediaId={media._id} isContentRatingReview={isContentRatingReview} />
                </div>
              </div>
            </div>
          );
        })}
        {this.state.currentMedia ? (
            <Modal className={"modal-big-thumb modal-big"} centered backdrop={true} show={this.state.currentMedia ? true : false} onHide={()=>this.setState({currentMedia : null})}>
                <Modal.Header closeButton>
                    <Modal.Title style={{display:"flex", width : "100%", "justifyContent" : "space-between"}}>
                      <div>
                      Vidéo : <a href={createLink("mediaviewerVideo", {media : this.state.currentMedia})} rel="noopener noreferrer" target="_blank" title="Voir la vidéo">{legendToTitle(this.state.currentMedia.legend)}</a>
                      </div>
                      <span className={`gender-button ${stateCurrentGender.className}`}>{stateCurrentGender.label}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactPlayer
                            url={this.state.currentMedia.videoUrl}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="80vh"
                            className={"player"}
                        />
                </Modal.Body>
            </Modal>
        ) : null}  
        {this.state.contentHistoryId ? (
              <Modal className={"modal-big-thumb"} style={{width : "50%", left : "25%"}} centered backdrop={true} show={this.state.contentHistoryId ? true : false} onHide={()=>this.setState({contentHistoryId : null})}>
                  <Modal.Header closeButton>
                      <Modal.Title>
                      Historique des modérations
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <ContentHistory mediaId={this.state.contentHistoryId} />
                  </Modal.Body>
              </Modal>
          ) : null}
      </div>
    );
  }
}

export default ContentVideos;